.header{
    /* margin-top: 60px; */
    /* height: 50vh; */
}

.headerTitles{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Lora", serif;
    color: #444;
}

.headerTitleSm{
    position: absolute;
    top: 40%;
    font-size: 20px;
    color: white;
}

.headerTitleLg{
    position: absolute;
    top: 24%;
    font-size: 100px;
    color: white
}

.headerImg{
    width: 100%;
    height: 800px;
    margin-top: 80px;
    object-fit: cover;
}

.overlay{
    position: absolute;
    top: 2;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0,.6);
    object-fit: cover;
}

@media screen and (max-width: 820px) {
    .header{
        display: none ;
        visibility: hidden;
    }
}