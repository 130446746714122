.singlePost{
    flex: 9;
}

.singlePostWrapper{
    padding: 20px;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    margin-right: 3vw;
}

.singlePostImg{
    width: 50%;
    height: 50%;
    border-radius: 5px;
    object-fit: cover;
    margin: auto;
}

.singlePostTitle{
    text-align: center;
    margin: 20px;
    font-family: 'Lora', serif;
    font-size: 28px;
}

.singlePostTitleInput{
    text-align: center;
    margin: 10px;
    font-family: 'Lora', serif;
    font-size: 28px;
    border: none;
    color:grey;
    border-bottom: 1px solid lightgrey;
}

.singlePostTitleInput:focus{
    outline: none;
}

.singlePostEdit{
    float: right;
    font-size: 16px;
}

.singlePostIcon{
    margin-left: 10px;
    cursor: pointer;
}

.singlePostIcon:first-child{
    color: teal;
}

.singlePostIcon:last-child{
    color: tomato;
}

.singlePostInfo{
    margin-bottom: 20px;
    margin-left: 5vw;
    margin-right: 5vw;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: 'Varela Round', sans-serif;
    color: #b39656;
}

.singlePostDesc{
    color: #666;
    font-size: 18px;
    line-height: 25px;
    margin-left: 5vw;
    margin-right: 5vw;
}

.singlePostDesc::first-letter{
    margin-left: 20px;
    font-size: 30px;
    font-weight: 600;
}

.singlePostDescInput{
    border: none;
    color: #666;
    font-size: 18px;
    line-height: 25px;
    height: 450px;
}

.singlePostDescInput:focus{
    outline: none;
}

.singlePostButton{
    width: 100px;
    height: 40px;
    border: none;
    background-color: teal;
    padding: 5px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 30px;
    padding-left: 10px;

    margin-left:800px;
}

@media screen and (max-width: 820px) {
    .singlePostTitle{
        text-align: center;
        margin: 20px;
        font-family: 'Lora', serif;
        font-size: 22px;
    }
    .singlePostDesc{
        color: #666;
        font-size: 14px;
        line-height: 25px;
        margin-left: 2vw;
        margin-right: 2vw;
    }
    .singlePostDesc::first-letter{
        margin-left: 2vw;
        font-size: 26px;
        font-weight: 600;
    }
    .singlePostInfo{
        margin-bottom: 20px;
        margin-left: 2vw;
        margin-right: 2vw;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-family: 'Varela Round', sans-serif;
        color: #b39656;
    }
    
}

@media screen and (max-width: 400px) {
    .singlePostTitle{
        text-align: center;
        margin: 20px;
        font-family: 'Lora', serif;
        font-size: 30px;
    }
}