.post{
    width: 40%;
    margin: auto;
    animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.postImg{
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 7px;
    margin-top: 14px;
}

.postInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.postCats{
    font-family: 'Varela Round', sans-serif;
    font-size: 11px;
    color: #be9656;
    line-height: 20px;
    margin-top: 15px;
    margin-right: 10px;
    cursor: pointer;
}

.postTitle{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin-top: 15px;
    cursor: pointer;
}

.postDate{
    font-family: 'Lora', serif;
    font-style: italic;
    font-size: 13px;
    margin-top: 15px;
}

.postDesc{
    font-family: 'Varela Round', sans-serif;
    font-size: 14px;
    color: #444;
    line-height: 24px;
    margin-top: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-bottom: 14px;
}

@media screen and (max-width: 820px) {
    .post{
        width: 100%;
        margin: 0px 25px 40px 25px;
    }
}