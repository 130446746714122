.top{
    width: 100%;
    height: 50px;
    margin-top: 2vh;
    background-color: white;
    position: sticky;
    top: 0px;
    display: flex;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 999;
}

.topLeft,.topRight{
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topCenter{
    flex: 6;
}

.topIcon{
    font-size: 20px;
    margin-right: 10px;
    color: #444;
    cursor: pointer;
}

.topImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

.topList{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

button:active{
    border-style: outset;
}

.topListItem:hover{
    color: rgb(255, 255, 255);
    background-color: rgba(12,12,12,0.8);
    border-radius: 4px;
    transition: 0.3 ease;
}

.topListItem{
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
}

.topSearchIcon{
    font-size: 18px;
    color: #666;
    cursor: pointer;
    margin-left: 15px;
}



input[type=checkbox]{
    display: none;
}

.hamburger{
    display: none;
    font-size: 24px;
    user-select: none;
}
.infoIcon{
    display: none;
    font-size: 24px;
    user-select: none;
}

.topProfile{
    transition: opacity 500ms, display 500ms;
    position: absolute;
    height: 100vh;
    width: 100vw;
    visibility: hidden;
    opacity: 0;
}

.topProfileItem{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topProfileTitle{
    margin: 3px;
    margin-top: 5px;
    padding: 5px;
    width: 60%;
    border-top: 1px solid #a7a4a4;
    border-bottom: 1px solid #a7a4a4;
    font-family: 'Varela', sans-serif;
    font-size: 12px;
    color: #222;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.topProfileItem > img{
    width: auto;
    height: 20vh;
    margin-top: 15px;
}

.topProfileItem > p{
    font-family: 'Varela Round', sans-serif;
    font-size: 14px;
    color: #444;
    padding: 2vh;
    margin-left: 20vw;
    margin-right: 20vw;
    text-align: left;
    height: 30vh;
    overflow: auto;
}

.topProfileList{
    list-style: none;
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    height: 10vh;
}

.topProfileListItem{
    display: inline-flex;
    width: 50%;
    margin-top: 2em;
    margin-left: 0.7em;
    margin-right: 0.7em;
    cursor: pointer;
    /* font-family: 'Varela Round', sans-serif; */
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.topListItem-close{
    visibility: hidden;
}

@media only screen and (min-width: 501px) and (max-width: 820px) {
    .topLeft{
        flex: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10%;
    }
    .topRight{
        display: none;
    }
    .topCenter{
        transition: opacity 500ms, display 500ms;
        display: block;
        visibility: hidden;
        opacity: 0;
    }
    
    .topList{
        display: block;
        position: absolute;
        background-color: #fcfbf7;
        opacity: 0.97;
        top: 0;
        right: 0;
        left: 0;
        text-align: left;
        padding: 16px 0; 
        height: 100vh;
        width: 100vw;
    }
    .topList > li {
        margin-top: 10vh;
        margin-left: 30px;
        font-size: 60px;
    }
    .topListItem-close{
        visibility: visible;
    }

    .menuButton{
        background-color: rgba(0,0,0,0);
        border-color: rgba(0,0,0,0);
        font-size: 5vh;
        color: rgb(41, 39, 39);
    }
    .menuButton:hover{
        color: azure;
    }
    .catButton{
        background-color: rgba(0,0,0,0);
        border-color: rgba(0,0,0,0);
        font-size: 18px;
    }
    #checkbox_toggle[type=checkbox]:checked~.topCenter{
        transition: opacity 500ms, display 500ms;
        display: block;
        visibility: visible;
        opacity: 1;
    }
    #topProfile_toggle[type=checkbox]:checked~.topProfile{
        transition: opacity 500ms, display 500ms;
        visibility: visible;
        opacity: 1;
        background-color: #fcfbf7;
        top: 0;
        right: 0;
        left: 0;
        text-align: center;
        height: 100vh;
        width: 100vw;
    }
    .hamburger{
        display: block; 
        margin-left: 50%;
    }
    .infoIcon{
        margin-left:10%;
        display: block; 
        margin-top: 5px;
    }
    .topIcon{
        font-size: 30px;
        margin-right: 10px;
        color: #444;
        cursor: pointer;
        margin: 10px;
    }
}
@media screen and (max-width: 500px) {
    .topLeft{
        flex: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10%;
    }
    .topRight{
        display: none;
    }
    .topCenter{
        transition: opacity 500ms, display 500ms;
        display: block;
        visibility: hidden;
        opacity: 0;
    }
    
    .topList{
        display: block;
        position: absolute;
        background-color: #fcfbf7;
        opacity: 0.97;
        top: 0;
        right: 0;
        left: 0;
        text-align: left;
        padding: 16px 0; 
        height: 100vh;
        width: 100vw;
    }
    .topList > li {
        margin-top: 10vh;
        margin-left: 30px;
        font-size: 60px;
    }
    .topListItem-close{
        visibility: visible;
    }

    .menuButton{
        background-color: rgba(0,0,0,0);
        border-color: rgba(0,0,0,0);
        font-size: 5vh;
        color: rgb(41, 39, 39);
    }
    .menuButton:hover{
        color: azure;
    }
    .catButton{
        background-color: rgba(0,0,0,0);
        border-color: rgba(0,0,0,0);
        font-size: 18px;
    }
    #checkbox_toggle[type=checkbox]:checked~.topCenter{
        transition: opacity 500ms, display 500ms;
        display: block;
        visibility: visible;
        opacity: 1;
    }
    #topProfile_toggle[type=checkbox]:checked~.topProfile{
        transition: opacity 500ms, display 500ms;
        visibility: visible;
        opacity: 1;
        background-color: #fcfbf7;
        top: 0;
        right: 0;
        left: 0;
        text-align: center;
        height: 100vh;
        width: 100vw;
    }
    .hamburger{
        display: block; 
        margin-left: 15%;
    }
    .infoIcon{
        margin-left:10%;
        display: block; 
        margin-top: 5px;
    }
    .topIcon{
        font-size: 30px;
        margin-right: 10px;
        color: #444;
        cursor: pointer;
        margin: 10px;
    }
}