@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

*, *::before , *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.webautomation {
    min-height: 100vh;
    font-size: 16px;
    line-height: 1.3;
    font-family: 'Bebas Neue', cursive;
    overflow: hidden;
}

.wrapper-web {
    width: 700%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.logo {
    font-size: 8rem;
    position: fixed;
    z-index: 10;
    top: 15%;
    left: 4rem;
    line-height: 0.9;
}

.line {
    background-color: red;
    height: 1rem;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
}

section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

section.page::before {
    content: '';
    width: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

section.page {
    background-color: #250436;
}

.block {
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transform: translateX(-100%);
}

.first::before, .first .block {
    background-color: #590782;
}

.past::before, .second .block {
    background-color: #420e76;
}

.future::before, .third .block {
    background-color: #3b1172;
}
.future::before, .forth .block {
    background-color: #3b1172;
}
.future::before, .fifth .block {
    background-color: #3b1172;
}

.caption-web {
    position: absolute;
    bottom: 4rem;
    left: 4rem;
    width: 30%;
    font-weight: 400;
    color: #fffbfb;
    font-family: monospace;
    transform: translate(100%, 100%);
}

.page img {
    position: relative;
    z-index: 10;
    height: auto;
    width: 25vw;
    transform: translateY(-100%);
}

.huge-text-web {
    font-size: 16rem;
    width: 100%;
    text-align: center;
    color: #f8f4f4;
    position: absolute;
    /* top: 20%;
    left: 14%; */
    transform: translateY(100%);
    font-weight: 700;
}

.cucumber:first-letter{
    color: rgb(72, 255, 0);
}

.timeline {
    position: absolute;
    top: 4rem;
    right: 4rem;
    font-size: 3rem;
    text-align: right;
    transform: translateY(-200%);
}

.timeline span {
    display: block;
    font-size: 70%;
    color: #fff;
}

.quote-web {
    position: absolute;
    bottom: 4rem;
    right: 4rem;
    font-size: 2rem;
    width: 30%;
    text-align: right;
    transform: translateY(200%);
    color: white;
}

.quote-web::before, .quote-web::after {
    content: '';
    color: #fff;
}


@media only screen and (max-width: 400px) and (max-height: 700px) {
    .huge-text-web {
        font-size: 12rem;
        top: 20%;
        left: 0;
    }

    .logo {
        font-size: 6rem;
        top: 15%;
        left: 2rem;
    }

    .page img {
        left:24.5%;
        width: 50vw;
    }

    .quote-web {
        display: none;
    }

    .timeline {
        position: absolute;
        top: 4rem;
        right: 2rem;
        font-size: 3rem;
    }

    .caption-web {
        position: absolute;
        width: 44%;
        left: 7%;
        bottom: 27%;
        font-size: 11px;
    }
}

@media only screen and (max-width: 400px) and (min-height: 700px) {
    .huge-text-web {
        font-size: 12rem;
        top: 20%;
        left: 0;
    }

    .logo {
        font-size: 6rem;
        top: 15%;
        left: 2rem;
    }

    .page img {
        left:25.5%;
        bottom: 10%;
        width: 50vw;
    }

    .quote-web {
        display: none;
    }

    .timeline {
        position: absolute;
        top: 4rem;
        right: 1rem;
        font-size: 3rem;
    }

    .caption-web {
        position: absolute;
        width: 46%;
        left: 6%;
        bottom: 40%;
        font-size: 11px;
    }
}

@media only screen and (min-width: 400px) and (max-width: 720px) and (max-height: 960px) {
    .huge-text-web {
        font-size: 12rem;
        top: 25%;
        left: 6%;
    }
    .timeline {
        right: 2rem;
    }
    .page img {
        left:25%;
        bottom: 10%;
        width: 50vw;
    }
    .caption-web {
        position: absolute;
        width: 45%;
        left: 7%;
        bottom: 35%;
        font-size: 11px;
    }
}

/* ipad mini */
@media only screen and (min-width: 721px) and (max-width: 1023px) and (max-height: 1179px) {
    .huge-text-web {
        font-size: 14rem;
        top: 25%;
        left: 4%;
    }
    .timeline {
        right: 2rem;
    }
    .page img {
        left:25%;
        bottom: 5%;
        width: 50vw;
    }
    .caption-web {
        position: absolute;
        width: 45%;
        left: 7%;
        bottom: 20%;
        font-size: 16px;
    }
    .quote-web {
        bottom: 30%;
        right: 52%;
        font-size: 2rem;
        width: 45%;
        color: white;
    }
}

/* ipad air */
@media only screen and (min-width: 769px) and (max-width: 1023px) and (max-height: 1365px) {
    .huge-text-web {
        font-size: 14rem;
        top: 25%;
        left: 4%;
    }
    .timeline {
        right: 2rem;
    }
    .page img {
        left:25.5%;
        bottom: 5%;
        width: 50vw;
    }
    .caption-web {
        position: absolute;
        width: 40%;
        left: 10%;
        bottom: 12%;
        font-size: 18px;
    }
    .quote-web {
        display: none;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1255px) and (max-height: 1367px) {
    .huge-text-web {
        font-size: 14rem;
        top: 20%;
        left:-2%;
    }
    .page img {
        left:25.5%;
        width: 50vw;
    }
    .timeline {
        position: absolute;
        top: 4rem;
        right: 1rem;
        font-size: 5rem;
    }
    .caption-web {
        position: absolute;
        width: 40%;
        left: 4%;
        bottom: 10%;
        font-size: 2vh;
    }
    .quote-web {
        bottom: 8rem;
        right: 4rem;
        font-size: 2rem;
        width: 40%;
        color: white;
    }
}

@media only screen and (min-width: 1255px) and (max-width: 1700px) {
    .huge-text-web {
        font-size: 12em;
        /* top: 20%;
        left: 13%; */
        top: -10%;
        letter-spacing: 20px
    }
}

@media only screen and (min-width: 1700px) {
    .huge-text-web {
        font-size: 12em;
        /* top: 20%;
        left: 13%; */
        top: 0%;
        letter-spacing: 20px
    }
}